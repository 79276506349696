<template>
  <mdb-container fluid class="p-0 classic-form-page">
    <mdb-navbar style="background-color: #173862;" class="justify-content-between">
      <mdb-navbar-brand href="/">
        <img src="/img/OWG-logo.svg" height="30" alt="">
      </mdb-navbar-brand>
      <span class="navbar-text" style="color:#e91951;font-weight: bold;float: right;">
        <a href="javascript:;" v-on:click="changelang('fr')" style="color: #e91951; text-decoration: underline;">FR</a> | <a href="javascript:;" v-on:click="changelang('en')" style="color: #e91951;">EN</a>
      </span>
    </mdb-navbar>
    <mdb-view class="">
      <mdb-mask
        overlay="stylish-strong"
        class="d-flex justify-content-center"
      >
        <mdb-container class="pt-5">
          <mdb-row>
            <div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">
              <mdb-card id="classic-card">
                <mdb-card-body class="z-depth-2 white-text">
                  <div class="form-header" style="background-color: #173862;">
                    <h3><i class="fas fa-user mt-2 mb-2"></i> {{$t('login.log_in')}}:</h3>
                  </div>
                  <mdb-alert color="danger" v-if="errormsg">{{errormsg}}</mdb-alert>
                  <mdb-input
                    :label="$t('login.your_email')"
                    labelColor="white"
                    inputClass="input-form-control"
                    v-model="username"
                    @keyup.enter.native="checkforenterpressed"
                  />
                  <mdb-input
                    :label="$t('login.your_password')"
                    labelColor="white"
                    type="password"
                    inputClass="input-form-control"
                    v-model="password"
                    @keyup.enter.native="checkforenterpressed"
                  />
                  <div class="text-center mt-4 black-text">
                    <mdb-btn style="background-color: #e91951!important;font-weight: bold;" v-on:click="login">{{$t('login.login')}}</mdb-btn>
                  </div>
                  <hr />
                  <a href="javascript:;" style="color: #fff;" v-on:click="lostpassword">{{$t('login.lost_password')}}</a>
                </mdb-card-body>
              </mdb-card>
            </div>
          </mdb-row>
        </mdb-container>
      </mdb-mask>
    </mdb-view>
    <mdb-modal :show="showlostpassmodal" @close="showlostpassmodal = false" danger>
      <mdb-modal-header class="text-center">
        <mdb-modal-title tag="h4" bold class="w-100">{{$t('login.lost_password')}}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="mx-3">
        <mdb-alert color="danger" v-if="resetpwderror">{{resetpwderror}}</mdb-alert>
        <mdb-alert color="success" v-if="resetpwdsuccess">{{resetpwdsuccess}}</mdb-alert>
        <mdb-input :label="$t('login.your_email')" icon="envelope" type="email" class="mb-5" iconClass="grey-text" v-model="resetpwdemail"/>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn v-if="showresetbtn" v-on:click="sendresetpassword" outline="danger">{{$t('login.reset_password')}} <mdb-icon icon="paper-plane" class="ml-1"/></mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <mdb-modal :show="show2famodal" danger>
      <mdb-modal-header class="text-center">
        <mdb-modal-title tag="h4" bold class="w-100">2FA SECURITY</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="mx-3">
        <mdb-alert color="danger" v-if="verify2faerror">{{verify2faerror}}</mdb-alert>
        <mdb-input label="code" icon="number" type="number" class="mb-5" iconClass="grey-text" v-model="code2fa"/>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-spinner v-if="processingvalidation" />
        <mdb-btn v-on:click="loginwith2facode" outline="danger" :disabled="code2fa.length !== 6" :max="999999" v-else>Valider <mdb-icon icon="paper-plane" class="ml-1"/></mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </mdb-container>
</template>

<script>
import {
  mdbNavbar,
  mdbNavbarBrand,
  mdbContainer,
  mdbView,
  mdbMask,
  mdbRow,
  mdbCard,
  mdbCardBody,
  mdbInput,
  mdbBtn,
  mdbAlert,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbModalTitle,
  mdbIcon,
  mdbSpinner,
} from 'mdbvue';
import Auth from '../../libs/Auth';

export default {
  name: 'LoginPage',
  data() {
    return {
      username: '',
      password: '',
      code2fa: '',
      errormsg: '',
      show2famodal: false,
      verify2faerror: '',
      showlostpassmodal: false,
      resetpwdsuccess: null,
      resetpwderror: null,
      resetpwdemail: '',
      showresetbtn: true,
      processingvalidation: false,
    };
  },
  components: {
    mdbNavbar,
    mdbNavbarBrand,
    mdbContainer,
    mdbView,
    mdbMask,
    mdbRow,
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbBtn,
    mdbAlert,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbModalTitle,
    mdbIcon,
    mdbSpinner,
  },
  methods: {
    lostpassword() {
      this.showlostpassmodal = true;
    },
    sendresetpassword() {
      this.showresetbtn = false;
      const auth = new Auth();
      const self = this;
      self.resetpwdsuccess = null;
      self.resetpwderror = null;
      auth.sendResetPwdEmail(self.resetpwdemail).then((response) => {
        self.resetpwdemail = '';
        self.showresetbtn = true;
        if (response.status === 'OK') {
          self.resetpwdsuccess = response.msg;
        } else {
          self.resetpwderror = response.msg;
        }
      });
    },
    login() {
      const auth = new Auth();
      const self = this;
      const redirect = this.$route.query.next || '/';
      auth.login(self.username, self.password, self.code2fa).then((response) => {
        self.processingvalidation = false;
        if (response.status === 'OK') {
          if (response.data.mustuse2fatoconnect) {
            self.show2famodal = true;
          } else if (redirect === '/') {
            self.$router.push({ name: 'Home' });
          } else {
            self.$router.push({ path: redirect });
          }
        } else if (self.show2famodal) {
          self.verify2faerror = response.msg;
        } else {
          self.errormsg = response.msg;
          self.password = '';
        }
      });
    },
    loginwith2facode() {
      this.processingvalidation = true;
      this.login();
    },
    checkforenterpressed(e) {
      if (e.keyCode === 13) {
        this.login();
      }
    },
    changelang(lang) {
      this.$store.state.currentlang = lang;
      this.$i18n.locale = lang;
      window.localStorage.setItem('currentlang', lang);
    },
  },
};
</script>

<style scoped>
.classic-form-page {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  height: 100vh;
  width: 100%;
}

.classic-form-page .view {
  background-image: url("/public/images/owg-page-header_e5879cbe.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;
}

.card {
  background-color: rgba(229, 228, 255, 0.2);
}

.classic-form-page h6 {
  line-height: 1.7;
}

.classic-form-page .navbar {
  transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.classic-form-page .navbar .md-form {
  margin: 0;
}

.top-nav-collapse {
  background: #424f95 !important;
}

@media (max-width: 768px) {
  .classic-form-page .navbar:not(.top-nav-collapse) {
    background: #424f95 !important;
  }
}

.classic-form-page label {
  color: #fff !important;
}

</style>
<style>
.input-form-control {
  color:#FFF!important;
}
</style>
